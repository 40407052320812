import { KeyPoint } from 'components/KeyPoint/KeyPoint';
import { KeyTopic } from 'components/KeyTopics/KeyTopics';
import { analytics } from 'lib/analytics';
import { MILLISECONDS_IN_SECOND } from 'constants/time';

export type StreamEndpoint = 'open_research' | 'open_research_overview';

export type Endpoint =
  | 'title'
  | 'open_research_sources'
  | 'open_research_keypoints'
  | 'open_research_keytopics'
  | 'tag'
  | 'random_questions'
  | 'suggested_questions'
  | 'open_research_statistics'
  | StreamEndpoint;

export interface RequestData {
  query_id: string;
  question_id: number;
  question: string;
  source_list: string[];
  followup_qas: FollowUpQA[];
}
//StreamAnswerRequest interface applies to overview streaming as well
export interface StreamAnswerRequest extends RequestData {
  with_upload: boolean;
}

export type Data = RequestData | StreamAnswerRequest;

export interface KeyTopicsRequest {
  question: string;
  question_id: number;
  query_id: string;
  source_list: string[];
  followup_qas: FollowUpQA[];
}

export interface StatisticsRequest {
  question: string;
  question_id: number;
  query_id: string;
  source_list: string[];
  followup_qas: FollowUpQA[];
  with_upload: boolean;
}

export interface FollowUpQA {
  question: string;
  answer: string;
}

interface RandomQuestionRequest {
  tag: string;
  profession: string;
  previous_questions: string[];
  num_questions: number;
}

interface SuggestedQuestionsRequest {
  query_id: string;
  previous_questions: string[];
  question_id: number;
  num_questions: number;
}

interface MakeRequestReturn {
  response: Response;
  abortController: AbortController;
}

export interface StreamPostReturn {
  abort: () => void;
}

export interface Source {
  url: string;
  title: string;
}

export interface AbortControllerReturn {
  abortController: AbortController;
}
export interface KeyPointsReturn extends AbortControllerReturn {
  result: KeyPoint[];
}
export interface KeyTopicsReturn extends AbortControllerReturn {
  result: KeyTopic[];
}

export interface StatisticsBarResponse {
  type: 'bar';
  graphTitle: string;
  unit?: string;
  data: {
    id?: string | number;
    label: string;
    value: number;
  }[];
}

export interface StatisticsLineResponse {
  type: 'line';
  graphTitle: string;
  unit?: string;
  data: {
    id?: string | number;
    label: string;
    data: { x: string | number; y: string | number }[];
  }[];
}

export interface StatisticsPieResponse {
  type: 'pie';
  graphTitle: string;
  unit?: string;
  data: {
    id?: string | number;
    label: string;
    value: number;
  }[];
}

export type StatisticsResponse =
  | StatisticsPieResponse
  | StatisticsLineResponse
  | StatisticsBarResponse;

export class QnaAPI {
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
  }

  private async makeRequest<T>(
    endpoint: Endpoint,
    data: T,
  ): Promise<MakeRequestReturn> {
    const abortController = new AbortController();
    const start = performance.now();
    const response = await fetch(`${this.baseUrl}/${endpoint}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ data }),
      signal: abortController.signal,
    });
    const end = performance.now();
    analytics.generic(
      null, // user
      'qna_api',
      {
        endpoint,
        latency: (end - start) / MILLISECONDS_IN_SECOND, // in seconds
        responseStatus: response?.status,
      },
    );
    return { response, abortController };
  }

  private async streamPost<T>(
    endpoint: Endpoint,
    data: T,
    chunkCb: (chunk: string) => void,
    endCb?: (content: string) => void,
  ): Promise<StreamPostReturn> {
    const { response, abortController } = await this.makeRequest(
      endpoint,
      data,
    );
    if (!response.body) {
      throw new Error('No response from the server');
    }
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    (async () => {
      let done, value;
      let content = '';
      while (!done) {
        ({ value, done } = await reader.read());
        const chunk = decoder.decode(value);
        content += chunk;
        chunkCb(chunk);
      }
      if (endCb) endCb(content);
    })();
    return {
      abort: () => {
        reader.cancel();
        abortController.abort();
      },
    };
  }

  private async jsonPost<T>(endpoint: Endpoint, data: T) {
    const { response } = await this.makeRequest(endpoint, data);
    const result = await response.json();
    return result;
  }

  public async fetchTitle(query: string): Promise<string> {
    const result = await this.jsonPost('title', { query });
    return result.data.title;
  }

  public async fetchSources(
    question: string,
    question_id: number,
    query_id: string,
  ): Promise<Source[]> {
    const result = await this.jsonPost('open_research_sources', {
      question,
      question_id,
      query_id,
    });
    return result.data.sources;
  }

  public async fetchTag(
    question: string,
    previous_tags: string[] = [],
  ): Promise<string[]> {
    const result = await this.jsonPost('tag', { question, previous_tags });
    return result.tags;
  }

  public async stream(
    data: Data,
    chunkCb: (chunk: string) => void,
    endCb?: (content: string) => void,
    streamEndpoint: StreamEndpoint = 'open_research',
  ): Promise<() => void> {
    const { abort } = await this.streamPost(
      streamEndpoint,
      data,
      chunkCb,
      endCb,
    );
    return abort;
  }

  public async fetchRandomQuestions(
    data: RandomQuestionRequest,
  ): Promise<string[]> {
    const result = await this.jsonPost('random_questions', data);
    return result.questions;
  }

  public async fetchSuggestedQuestions(
    data: SuggestedQuestionsRequest,
  ): Promise<string[]> {
    const result = await this.jsonPost('suggested_questions', data);
    return result?.data?.questions;
  }

  public async fetchKeyPoints(data: RequestData): Promise<KeyPointsReturn> {
    const { response, abortController } = await this.makeRequest(
      'open_research_keypoints',
      data,
    );
    const jsonResult = await response.json();
    const result = jsonResult?.data?.facts;
    return { result, abortController };
  }

  public async fetchKeyTopics(
    data: KeyTopicsRequest,
  ): Promise<KeyTopicsReturn> {
    const { response, abortController } = await this.makeRequest(
      'open_research_keytopics',
      data,
    );
    const jsonResult = await response.json();
    const result = jsonResult?.data?.keytopics;
    return { result, abortController };
  }

  public async fetchStatistics(
    data: StatisticsRequest,
  ): Promise<StatisticsResponse[]> {
    const result = await this.jsonPost('open_research_statistics', data);
    return Array.isArray(result.data) ? result.data : [result.data];
  }
}

if (!process.env.NEXT_PUBLIC_QUERY_API_BASE_URL) {
  throw new Error('NEXT_PUBLIC_QUERY_API_BASE_URL env not set');
}

export const qnaAPI = new QnaAPI(process.env.NEXT_PUBLIC_QUERY_API_BASE_URL);

export const MAX_ANONYMOUS_QUESTIONS = 3;
