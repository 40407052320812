import useAuth from 'hooks/useAuth';
import { Discovery, useDiscovery } from 'providers/discovery';
import { Search, useSearch } from 'providers/search';
import { SavedSearches, useSavedSearches } from 'providers/savedSearches';
import { SearchForm, useSearchForm } from 'providers/searchForm';
import { Share, useShare } from 'providers/share';
import {
  SourceManagement,
  useSourceManagement,
} from 'providers/sourceManagement';
import { FeatureFlags, useFeatureFlags } from './featureFlags';
import { ServerTypes } from 'lib/types';
import {
  FollowUpQuestionForm,
  useFollowUpQuestionForm,
} from './followUpQuestionForm';
import { RefItem, useRefItem } from './refItem';
import { useParams } from 'next/navigation';
import { Timer, useTimer } from './timer';
import {
  AnonymousSearchForm,
  useAnonymousSearchForm,
} from './anonymousQuestionAnswerForm';
import { AnonymousSearch, useAnonymousSearch } from './anonymousSearch';

export class SearchCompanion {
  constructor(
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private queryId: string,
    private hasInitialised: RefItem<boolean>,
    public timer: Timer,
    public discovery: Discovery,
    public search: Search,
    public anonymousSearch: AnonymousSearch,
    public savedSearches: SavedSearches,
    public searchForm: SearchForm,
    public anonymousSearchForm: AnonymousSearchForm,
    public followUpQuestionForm: FollowUpQuestionForm,
    public share: Share,
    public sourceManagement: SourceManagement,
    public featureFlags: FeatureFlags,
  ) {}

  public loadSearch(queryId: string) {
    this.search.clear();
    this.search.getSummary().disableSummary();
    this.search.fetchFromDb(queryId);
  }

  public initialise() {
    if (this.hasInitialised.get()) return;
    this.hasInitialised.set(true);
    this.timer.start();
    if (this.queryId) this.loadSearch(this.queryId);
    if (!this.user) return;
    this.savedSearches.fetch();
    this.discovery.fetch();
    this.featureFlags.fetch();
  }
}

export function useSearchCompanion(): SearchCompanion {
  const { queryId } = useParams<{ queryId: string }>();
  const savedSearches = useSavedSearches();
  const search = useSearch(savedSearches);
  const anonymousSearch = useAnonymousSearch();
  const [user] = useAuth();
  const timer = useTimer(5000);
  return new SearchCompanion(
    user,
    queryId,
    useRefItem(false),
    timer,
    useDiscovery(search, timer),
    search,
    anonymousSearch,
    savedSearches,
    useSearchForm(search),
    useAnonymousSearchForm(anonymousSearch),
    useFollowUpQuestionForm(search),
    useShare(search),
    useSourceManagement(search),
    useFeatureFlags(),
  );
}
