import useAuth from 'hooks/useAuth';
import { ServerTypes } from 'lib/types';
import { qnaAPI } from './qnaAPI';
import { Search } from './search';
import { StateItem, useStateItem } from './stateItem';
import { analytics } from 'lib/analytics';
import { Timer } from './timer';

const discoveryTexts = [
  'Discover',
  'Click here for more',
  'Random question',
  'Click again',
  'Trending now',
  'New question',
  'Personalised content',
];

export class Discovery {
  constructor(
    private user: Partial<ServerTypes.UserWithProfessions> | null,
    private timer: Timer,
    private search: Search,
    private questions: StateItem<string[]>,
    private textIdx: StateItem<number>,
  ) {}

  /**
   * Creates a query for the current question.
   * 
   * Cannot be used in rendering (changes state and relies on refs).
   * 
   * @returns 
   */
  public perform(): void {
    const questions = this.questions.get(true);
    if (questions.length === 0) return;
    const questionIdx = this.questionIdx(true);
    const question = questions[questionIdx];
    analytics.clickedDiscover(this.user, question);
    this.search.create(question);
    if (questions.length <= 5) this.fetch();
    this.questions.set([
      ...questions.slice(0, questionIdx),
      ...questions.slice(questionIdx + 1),
    ]);
    this.incrementTextIdx();
  }

  /**
   * Appends discovery questions to the list.
   * 
   * Cannot be used in rendering (changes state and relies on refs).
   * 
   * @returns 
   */
  private appendDisoveryQuestions(questions: string[]): void {
    const old = this.questions.get(true);
    this.questions.set([...old, ...questions]);
  }

  /**
   * Retrieve more questions.
   * 
   * Cannot be used in rendering (changes state, relies on refs and an API).
   * 
   * @returns 
   */
  async fetch() {
    const old = this.questions.get(true);
    if (old.length >= 5) return;
    const questions = await qnaAPI.fetchRandomQuestions({
      num_questions: 10,
      previous_questions: await this.search.previousQuestions(10),
      profession: this.user?.professions?.[0]?.name || '',
      tag: '',
    });
    this.appendDisoveryQuestions(questions);
  }

  /**
   * Retrieve the current question idx
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * @returns 
   */
  private questionIdx(latest = false): number {
    return this.timer.getTicks(latest) % this.numQuestions(latest);
  }

  /**
   * Retrieve the total number of questions
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * 
   * @returns 
   */
  private numQuestions(latest = false): number {
    return this.questions.get(latest).length;
  }

  /**
   * Whether there are any questions or not
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * 
   * @returns 
   */
  private hasQuestions(latest = false): boolean {
    return this.numQuestions(latest) > 0;
  }

  /**
   * Whether there are any questions or not
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * 
   * @returns 
   */
  public isDisabled(latest = false): boolean {
    return !this.hasQuestions(latest) || this.search.getIsStreaming(latest);
  }

  /**
   * Increments the index of the dicovery button text
   * 
   * Cannot be used in rendering (changes state, relies on refs and an API).
   * 
   * @returns 
   */
  private incrementTextIdx() {
    const old = this.textIdx.get(true);
    this.textIdx.set((old + 1) % discoveryTexts.length);
  }

  /**
   * Get the text of the discovery button
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * 
   * @returns 
   */
  public getButtonText(latest = false): string {
    const textIdx = this.textIdx.get(latest);
    return discoveryTexts[textIdx];
  }

  /**
   * Get the text for the discovery button tool tip
   * 
   * @param latest whether to use the latest or state value (false should be used when rendering)
   * 
   * @returns 
   */
  public getTooltip(latest = false): string {
    if (!this.hasQuestions(latest)) return '';
    const questions = this.questions.get(latest);
    const questionIdx = this.questionIdx(latest);
    return questions[questionIdx];
  }
}

export function useDiscovery(
  search: Search,
  timer: Timer,
): Discovery {
  const [user] = useAuth();
  const discovery = new Discovery(
    user,
    timer,
    search,
    useStateItem([]),
    useStateItem(0),
  );
  return discovery;
}
