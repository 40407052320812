import { StateItem, useStateItem } from 'providers/stateItem';
import { RefItem, useRefItem } from './refItem';
import { ChangeEvent, FormEvent } from 'react';
import { AnonymousSearch } from './anonymousSearch';
export class AnonymousSearchForm {
  constructor(
    private anonymousSearch: AnonymousSearch,
    private searchInputValue: StateItem<string>,
    private errorMessage: StateItem<string>,
    private isSubmitting: RefItem<boolean>,
  ) {}

  public getSearchInputValue(latest: boolean): string {
    return this.searchInputValue.get(latest);
  }

  public onValueChange(e: ChangeEvent<HTMLInputElement>): void {
    this.searchInputValue.set(e.target.value);
  }

  public getErrorMessage(latest: boolean): string {
    return this.errorMessage.get(latest);
  }

  public setErrorMessage(message: string) {
    this.errorMessage.set(message);
  }

  public getIsSubmitting(): boolean {
    return this.isSubmitting.get();
  }

  private clearErrorMessage() {
    Boolean(this.getErrorMessage(true)) && this.setErrorMessage('');
  }

  public async onSubmit(e: FormEvent<HTMLFormElement>): Promise<void> {
    e.preventDefault();
    this.clearErrorMessage();
    if (this.isSubmitting.get()) return;
    const question = this.searchInputValue.get(true).trim();
    if (!Boolean(question)) {
      this.setErrorMessage(' Empty input, please ask a question.');
      return;
    }
    this.isSubmitting.set(true);
    try {
      await this.anonymousSearch.create(question);
      this.searchInputValue.set('');
      //TODO: WHY IS THIS HERE
      if (!this.isSubmitting) return;
    } catch (error) {
      this.setErrorMessage(
        error.message ?? ' Something went wrong. Please try again.',
      );
    } finally {
      this.isSubmitting.set(false);
    }
  }

  public isDisabled(): boolean {
    return this.getIsSubmitting();
  }
}

export function useAnonymousSearchForm(anonymousSearch: AnonymousSearch) {
  return new AnonymousSearchForm(
    anonymousSearch,
    useStateItem(''),
    useStateItem(''),
    useRefItem(false),
  );
}
