import {
  string,
  object,
  nativeEnum,
  number,
  boolean,
  array,
  union,
  literal,
} from 'zod';

export enum ReactionEnum {
  LIKE = 'LIKE',
  DISLIKE = 'DISLIKE',
  UNSET = 'UNSET',
}

export enum StripeCustomerPortalFlowDataType {
  PAYMENT_METHOD_UPDATE = 'payment_method_update',
  SUBSCRIPTION_CANCEL = 'subscription_cancel',
  SUBSCRIPTION_UPDATE = 'subscription_update',
  SUBSCRIPTION_UPDATE_CONFIRM = 'subscription_update_confirm',
}

export enum UseCaseTypeEnum {
  RESEARCH = 'RESEARCH',
}
export const isNumber = number().int();
export const isId = isNumber;
export const isUUID = string().uuid();

export const isAuthUserId = isUUID;

export const isSearchName = string().default('');

export const isQueryId = isUUID; //TODO: CHECK IF IT AFFECTS EXISTING FUNCTIONALITY

export const isUser = object({
  id: isId,
  authUserId: isAuthUserId,
});

export const isUserUpdate = isUser.merge(
  object({
    isNewUser: boolean().optional(),
    firstName: string().optional(),
    lastName: string().optional(),
    phoneNumber: string().optional(),
    companyName: string().optional(),
    role: string().optional(),
    team: string().optional(),
  })
);

export const isCreateUserSubscription = object({
  id: isId,
  authUserId: isAuthUserId,
  priceId: string().optional(),
});

export const isUpdateUserSubscription = object({
  id: isId,
  authUserId: isAuthUserId,
  subscriptionId: string(),
});

export const isUpdateUserSubscriptionTrialEnd = object({
  id: isId,
  authUserId: isAuthUserId,
  subscriptionId: string(),
});

export const isUserCustomerPortal = object({
  id: isId,
  authUserId: isAuthUserId,
  flowDataType: nativeEnum(StripeCustomerPortalFlowDataType).optional(),
});

export const isUpdateUserProfession = object({
  id: isId,
  authUserId: isAuthUserId,
  professionId: isId,
});

export const isQuestion = object({
  data: object({
    question: string(),
    s3_paths: array(string()),
    organization_id: isId.nullable(),
    industry_id: isId.nullable(),
  }),
});

export const isResearchData = object({
  query_id: isId,
  query: string(),
  search_id: isId,
});

export const isResearchQuestion = object({
  data: isResearchData.extend({
    question: string(),
  }),
});

export const isSuggestedQuestions = object({
  data: isResearchData,
});

export const isQuestionAnswer = object({
  question: string(),
  answer: string(),
  comment: string().default(''),
  reaction: nativeEnum(ReactionEnum).default(ReactionEnum.UNSET),
  queryId: isQueryId,
});

export const isBatchOrganizationsAndQuestions = object({
  data: object({
    organization_ids: array(number().int()),
    email: string(),
    questions: array(string()),
  }),
});

export const isOrganizationOnePager = object({
  data: object({
    organization_id: number().int(),
    email: string(),
  }),
});

export const isDownloadableQuestionAnswer = object({
  question: string().default(''),
  answer: string().default(''),
  comment: string().default(''),
  reaction: nativeEnum(ReactionEnum).default(ReactionEnum.UNSET),
  searchId: isId,
  fileURL: string(),
  companyName: string(),
});

export const isQAndAUpdate = object({
  id: isId,
  question: string().optional(),
  answer: string().optional(),
  comment: string().optional(),
  reaction: nativeEnum(ReactionEnum).optional(),
});

export const isLastNQuestions = object({
  numberOfQuestionsToGet: isNumber,
  authUserId: isAuthUserId,
});

export const isSearchUpdate = object({
  id: isId,
  name: string().default(''),
  isActive: boolean().optional(),
  organizationId: number().optional(),
  industryId: number().optional(),
});

export const isSearchId = object({ searchId: isId });

export const isDocumentsToProcess = object({
  data: object({
    s3_paths: array(string()),
    is_generate_title: boolean(),
  }),
});

export const isDocumentsToProcessAndCreate = object({
  data: object({
    s3_paths: array(string()),
    is_generate_title: boolean(),
    activeSearchId: isId,
    uploadedFiles: array(
      object({
        name: string(),
        size: number(),
      })
    ),
  }),
});

export const isFile = object({
  name: string(),
  size: number(),
  fileURL: string(),
  isSelected: boolean().default(true),
  searchId: isId,
});

export const isFileUpdate = object({
  id: isId,
  isSelected: boolean(),
});

export const isInvite = object({
  authUserId: isAuthUserId,
  recipientEmail: string().email(),
});

export const isSearchWithUseCase = object({
  name: isSearchName,
  useCaseType: nativeEnum(UseCaseTypeEnum),
});

export const isSearchWithUseCaseAndQuery = object({
  useCaseType: nativeEnum(UseCaseTypeEnum),
  queryTermText: string().min(1),
});

export const isSearchWithUseCaseList = object({
  useCaseType: nativeEnum(UseCaseTypeEnum),
});

export const isSearchWithUseCaseUpdate = object({
  id: isId,
  name: isSearchName.optional(),
  isActive: boolean().optional(),
  useCaseType: nativeEnum(UseCaseTypeEnum).optional(),
});

export const isSignUp = object({
  email: string(),
  password: string(),
});

export const isResendSignUp = object({
  email: string(),
});

export const isForgotPasswordSubmit = object({
  email: string(),
  code: string(),
  password: string(),
});

export const isConfirmSignup = object({
  email: string(),
  code: string(),
});

export const isGetUser = object({
  accessToken: string(),
});

export const isSSOLogin = object({
  code: string(),
});

export const isSearchQuestionOnly = object({
  question: string(),
});

export const isSearchSetAnswer = object({
  queryId: string(),
  answer: string(),
});

export const isSearchSetTags = object({
  queryId: string(),
  tags: array(string()),
});

export const isSearchSetTitle = object({
  queryId: string(),
  title: string(),
});

export const isSearchSetSources = object({
  queryId: string(),
  sources: array(object({ url: string(), title: string() })),
});

export const isStatisticsResponse = union([
  object({
    type: union([literal('bar'), literal('pie')]),
    graphTitle: string(),
    unit: string().optional(),
    data: array(
      object({
        id: union([number(), string()]).optional(),
        label: string(),
        value: number(),
      })
    ),
  }),
  object({
    type: literal('line'),
    graphTitle: string(),
    unit: string().optional(),
    data: array(
      object({
        id: union([number(), string()]).optional(),
        label: string(),
        data: array(
          object({
            x: union([string(), number()]),
            y: union([string(), number()]),
          })
        ),
      })
    ),
  }),
]);

export const isSearchSetStatistics = object({
  queryId: string(),
  statistics: isStatisticsResponse.array(),
});

export const isSearchSetDeletedSources = object({
  queryId: string(),
  deletedSources: array(object({ url: string(), title: string() })),
});

export const isAnonymousId = string().uuid();

export const isAnonymousQuestionAnswer = object({
  anonymousId: isAnonymousId,
  queryId: string().uuid(),
  question: string(),
  answer: string(),
  sources: array(object({ url: string(), title: string() })),
  tags: array(string()),
  title: string(),
  deleteExisting: boolean().optional(),
});

export const isAnonymousSearch = object({
  anonymousId: isAnonymousId,
  queryId: string().uuid(),
});

export const isSummary = object({
  searchQueryId: string().uuid(),
  questionAnswerId: isId,
});

export const isOverview = object({
  summaryId: isUUID,
  content: string().default(''),
});

export const isOverviewUpdate = object({
  id: isUUID,
  content: string(),
});

export const isKeyPoint = object({
  title: string(),
  value: string(),
  overviewId: isUUID,
});

export const isKeyPointsArray = array(isKeyPoint);

export const isKeyTopic = object({
  title: string(),
  content: string(),
  summaryId: isUUID,
});

export const isKeyTopicsArray = array(isKeyTopic);
