'use client';

import React, { createContext, useContext, ReactNode, useEffect } from 'react';
import { SearchCompanion, useSearchCompanion } from './searchCompanion';

const SearchContext = createContext<SearchCompanion | null>(null);

export function SearchProvider({ children }: { children: ReactNode }) {
  const searchCompanion = useSearchCompanion();

  useEffect(() => searchCompanion.initialise());
  return (
    <SearchContext.Provider value={searchCompanion}>
      {children}
    </SearchContext.Provider>
  );
}

export { SearchContext };

export function useSearchProvider() {
  return useContext(SearchContext);
}
